import React from 'react';
import './InlineVideo.scss';

const InlineVideo = ({ src, aspectRatio, poster, maxWidth }) => (
  <div
    className="c-inline-video__outer"
    style={{
      maxWidth: maxWidth ? maxWidth : 'none',
    }}
  >
    <div
      className="c-inline-video"
      style={{
        paddingBottom: `${aspectRatio ? aspectRatio * 100 : 56.25}%`,
      }}
    >
      <video
        src={src}
        autoPlay
        loop
        playsInline
        preload="auto"
        muted
        poster={poster}
      />
    </div>
  </div>
);

export default InlineVideo;
