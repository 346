import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import InlineVideo from '../InlineVideo';
import './LinkCard.scss';

const LinkCardContent = ({
  imgData,
  imageAlt,
  video,
  videoAspect,
  title,
  description,
  date,
  tags,
}) => (
  <>
    <div className="c-link-card__text">
      <h3 className="c-link-card__text__header">{title}</h3>
      {date && <div className="c-link-card__date f-label">{date}</div>}
      {tags && (
        <div className="c-link-card__tags">
          {tags.map(tag => (
            <span className="f-tag" key={`tag${tag}`}>
              {tag}
            </span>
          ))}
        </div>
      )}
      <div className="c-link-card__text__desc">{description}</div>
    </div>
    {video ? (
      <div className="c-link-card__image">
        <InlineVideo src={video} aspectRatio={videoAspect} />
      </div>
    ) : imgData ? (
      <div className="c-link-card__image">
        <Img fluid={imgData} alt={imageAlt} />
      </div>
    ) : null}
  </>
);

const LinkCard = props => {
  const classes = `c-link-card ${props.isSmall ? 'c-link-card--small' : ''}`;

  return props.externalLink ? (
    <a
      href={props.externalLink}
      className={classes}
      target="_blank"
      rel="noopener noreferrer"
    >
      <LinkCardContent {...props} />
    </a>
  ) : (
    <Link to={props.url} className={classes}>
      <LinkCardContent {...props} />
    </Link>
  );
};

export default LinkCard;
