import React from 'react';
import './TileList.scss';

const TileList = ({ id, header, tight, children }) => (
  <div className={`c-tile-list ${tight ? 'c-tile-list--tight' : ''}`}>
    <h2 className="f-header-2" id={id}>
      {header}
    </h2>
    <ul aria-labelledby={id}>
      {children &&
        React.Children.map(children, (child, i) => (
          <li className="c-tile-list__item" key={child.props.id}>
            {child}
          </li>
        ))}
    </ul>
  </div>
);

export default TileList;
